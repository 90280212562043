import firebase from 'firebase/app';
import Vue from "vue";

export const menuStore = {
    namespaced: true,
    state: {
        foodCategories: [],
        foodItems: [],
        menuLoaded: false,
        categoryFocus: "",
        appBarExtended: true,
        categoryToFocus: "",
    },
    getters: {
        categoryToFocus(state) {
            return state.categoryToFocus
        },
        foodCategories(state) {
            return state.foodCategories.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
        },
        foodItems(state) {
            return state.foodItems.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
        },
        menuLoaded(state) {
            return state.menuLoaded
        },
        getItemByID: (state) => (id) => {
            return state.foodItems.find(food => food.id == id)
        },
        getCategoryInFocus(state) {
            return state.categoryFocus
        },
        appBarExtended(state) {
            return state.appBarExtended
        }

    },
    mutations: {

        // Misc
        STORE_CART_LOCALLY(state) {
            localStorage.foodCategories = JSON.stringify(state.foodCategories)
            localStorage.foodItems = JSON.stringify(state.foodItems)
        },
        SET_MENU_LOADED(state) {
            state.menuLoaded = true;
        },
        SET_APP_BAR_EXTENDED(state, val) {
            state.appBarExtended = val
        },

        // Category related
        SET_FOOD_CATEGORIES(state, arr) {
            Vue.set(state, 'foodCategories', arr)
        },
        ADD_FOOD_CATEGORY(state, obj) {
            state.foodCategories.push(obj)
        },
        DELETE_FOOD_CATEGORY(state, id) {
            let index = state.foodCategories.findIndex(category => category.id == id)
            state.foodCategories.splice(index, 1)
        },
        UPDATE_FOOD_CATEGORY(state, obj) {
            let index = state.foodCategories.findIndex(category => category.id == obj.id)
            Object.keys(obj).forEach(key => {
                Vue.set(state.foodCategories[index], key, obj[key])
            })
        },
        SET_CATEGORY_IN_FOCUS(state, cat) {
            state.categoryFocus = cat
        },
        SET_CATEGORY_TO_FOCUS(state, cat) {
            state.categoryToFocus = cat
        },

        // Item related
        SET_FOOD_ITEMS(state, arr) {
            Vue.set(state, 'foodItems', arr)
        },
        ADD_FOOD_ITEM(state, obj) {
            state.foodItems.push(obj)
        },
        DELETE_FOOD_ITEM(state, id) {
            let index = state.foodItems.findIndex(food => food.id == id)
            state.foodItems.splice(index, 1)
        },
        UPDATE_FOOD_ITEM(state, obj) {
            let index = state.foodItems.findIndex(item => item.id == obj.id)
            Object.keys(obj).forEach(key => {
                Vue.set(state.foodItems[index], key, obj[key])
            })
        },
        SET_FOOD_ATTRIBUTE(state, {key, value, foodId}) {
            let foodIndex = state.foodItems.findIndex(food => food.id == foodId);
            Vue.set(state.foodItems[foodIndex], key, value)
        },

        //EXTRA RELATED
        SET_EXTRAS(state, {arr, foodId}) {
            let foodIndex = state.foodItems.findIndex(food => food.id == foodId);
            Vue.set(state.foodItems[foodIndex], 'extras', arr)
        },

        //QUESTION RELATED
        SET_QUESTIONS(state, {arr, foodId}) {
            let foodIndex = state.foodItems.findIndex(food => food.id == foodId);
            Vue.set(state.foodItems[foodIndex], 'questions', arr)
        }
    },
    actions: {
        setCategoryInFocus({commit}, id) {
            commit("SET_CATEGORY_IN_FOCUS", id)
        },
        fetchFoodCategories({commit, rootState}) {
            return new Promise(((resolve) => {
                if (localStorage.foodCategories && localStorage.foodItems && !rootState.restaurantStore.outOfDate) {
                    commit("SET_FOOD_CATEGORIES", JSON.parse(localStorage.foodCategories));
                    commit("SET_FOOD_ITEMS", JSON.parse(localStorage.foodItems));
                    commit("SET_MENU_LOADED");
                    resolve()
                } else {
                    let categoriesPromise = firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id)
                        .collection("FoodCategories").get().then((querySnapshot) => {
                            let arr = querySnapshot.docs.map(function (documentSnapshot) {
                                let obj = documentSnapshot.data();
                                obj.id = documentSnapshot.id;
                                return obj
                            });
                            commit("SET_FOOD_CATEGORIES", arr);
                        });
                    let itemsPromise = firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id)
                        .collection("FoodItems").orderBy("index", "asc").get().then((querySnapshot) => {
                            let arr = querySnapshot.docs.map(function (documentSnapshot) {
                                let obj = documentSnapshot.data();
                                obj.id = documentSnapshot.id;
                                return obj
                            });
                            commit("SET_FOOD_ITEMS", arr);
                        });
                    Promise.all([categoriesPromise, itemsPromise]).then(() => {
                        commit("STORE_CART_LOCALLY");
                        commit("SET_MENU_LOADED");
                        resolve()
                    });
                }
            }))

        },
        ensureQueries({dispatch}, item) {
            return new Promise((resolve, reject) => {
                if (item.queries && item.queries.length > 0) {
                    let queryPromises = item.queries.map(query => {
                        if (!item[query]) {
                            return dispatch('fetchItemQuery', {query: query, foodId: item.id})
                        } else {
                            return Promise.resolve(false)
                        }
                    })
                    Promise.all(queryPromises).then((result) => {
                        resolve(result)
                    }).catch(reject)
                } else {
                    resolve(false)
                }
            })

        },
        fetchItemQuery({rootState, commit}, {query, foodId}) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").doc(foodId).collection('queries').doc(query).get().then(docSnapshot => {
                    let arr = []
                    if (docSnapshot.exists) {
                        arr = docSnapshot.data().items || []
                    }
                    //TODO: THIS IS A TEMPORARY WORKAROUND UNTIL ALL THE MENUS ARE RIGHT!
                    if (query == 'extras') {
                        arr.map(x => {
                            if (!x.category) {
                                x.category = 'extras'
                            }
                            return x
                        })
                    }
                    commit("SET_FOOD_ATTRIBUTE", {key: query, value: arr, foodId});
                    commit("STORE_CART_LOCALLY");
                    resolve([arr, query])
                }).catch(reject)
            })
        },
        setLastUpdated({rootState}) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).update({lastUpdated: firebase.firestore.FieldValue.serverTimestamp()}).then(() => {
                    resolve()
                    /*TODO: THIS SHOULD UPDATE THE LAST UPDATED FLAG FOR SLIGHTLY LESS DATA USAGE, BUT NOT V IMPORTANT
                    *  THIS WOULD MAKE IT SO THE RESTAURANT OWNER DOESNT HAVE TO LOAD THE SPEISEKARTE AGAIN NEXT TIME*/
                }).catch(err => {
                    console.log(err)
                    reject()
                })
            })
        },
        deleteFoodItem({rootState, commit}, obj) {
            let foodItemDelete = new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").doc(obj.id).delete().then(function () {
                    commit("DELETE_FOOD_ITEM", obj.id);
                    resolve()
                }).catch(function (error) {
                    console.error("Error removing FoodItem: ", error);
                    reject()
                })
            })
            let extraDelete = new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").doc(obj.id).collection("queries").doc('extras').delete().then(function () {
                    resolve()
                }).catch(function (error) {
                    console.error("Error removing extras: ", error);
                    reject()
                })
            })
            let questionDelete = new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").doc(obj.id).collection("queries").doc('questions').delete().then(function () {
                    resolve()
                }).catch(function (error) {
                    console.error("Error removing questions: ", error);
                    reject()
                })
            })
            Promise.all([foodItemDelete, extraDelete, questionDelete])
        },
        deleteFoodCategory({rootState, commit}, id) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodCategories").doc(id).delete().then(function () {
                    commit("DELETE_FOOD_CATEGORY", id);
                    resolve()
                }).catch(function (error) {
                    console.error("Error removing FoodCategory: ", error);
                    reject()
                });
            })
        },
        editFoodCategory({rootState, commit}, obj) {
            let id = obj.id
            delete obj.id
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodCategories").doc(id).update(obj).then(function () {
                    obj.id = id
                    commit("UPDATE_FOOD_CATEGORY", obj);
                    resolve(obj)
                }).catch(function (error) {
                    console.error("Error editing FoodCategory: ", error);
                    reject()
                });
            })
        },
        addFoodCategory({rootState, commit}, originalObj) {
            return new Promise((resolve, reject) => {
                let obj = JSON.parse(JSON.stringify(originalObj))
                if (obj.id) {
                    delete obj.id
                }
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodCategories").add(obj).then(docRef => {
                    obj.id = docRef.id
                    commit("ADD_FOOD_CATEGORY", obj);
                    resolve(obj)
                }).catch(function (error) {
                    console.error("Error adding FoodCategory: ", error);
                    reject()
                });
            })
        },
        addFoodItem({rootState, commit}, originalObj) {
            return new Promise((resolve, reject) => {
                let obj = JSON.parse(JSON.stringify(originalObj))
                if (obj.id) {
                    delete obj.id
                }
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").add(obj).then(docRef => {
                    obj.id = docRef.id
                    commit("ADD_FOOD_ITEM", obj);
                    resolve(obj)
                }).catch(function (error) {
                    console.error("Error adding FoodItem: ", error);
                    reject()
                });
            })
        },
        editFoodItem({rootState, commit}, obj) {
            let id = obj.id
            delete obj.id
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").doc(id).update(obj).then(() => {
                    obj.id = id
                    commit("UPDATE_FOOD_ITEM", obj);
                    resolve(obj)
                }).catch(function (error) {
                    console.error("Error editing FoodItem: ", error);
                    reject()
                });
            })
        },
        setExtras({rootState, commit}, obj) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").doc(obj.item).collection('queries').doc('extras').set(
                    {items: obj.extras}
                ).then(() => {
                    commit("SET_EXTRAS", {arr: obj.extras, foodId: obj.item});
                    resolve(obj)
                }).catch(function (error) {
                    console.error("Error setting Extras: ", error);
                    reject()
                });
            })
        },
        setQuestions({rootState, commit}, obj) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(rootState.restaurantStore.id).collection("FoodItems").doc(obj.item).collection('queries').doc('questions').set(
                    {items: obj.questions}
                ).then(() => {
                    commit("SET_QUESTIONS", {arr: obj.questions, foodId: obj.item});
                    resolve(obj)
                }).catch(function (error) {
                    console.error("Error setting Questions: ", error);
                    reject()
                });
            })
        },

    },
};
