<template>
  <span>
    <v-app-bar
        id="appBar"
        v-scroll="onScroll"
        v-model="appBarExtended"
        :hide-on-scroll="$route.name == 'menu'"
        app
        :color="appBarColor"
        flat
    >
      <v-app-bar-nav-icon
          :color="$route.name == 'index' ? 'white' : 'black'"
          style="touch-action: manipulation;"
          v-if="$vuetify.breakpoint.smAndDown" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
            <div  class="d-flex align-center">
                <div class="d-flex align-center pr-2" style="cursor:pointer" @click="$router.push({name:'index'})">
                <v-img :src="getImgUrl()" contain height="57"></v-img>
                </div>
            <v-btn large text
                   :color="$route.name == 'index' || !$route.name ? 'white' : 'black'"
                   @click="$router.push({name:'index'})">
                    HOME
            </v-btn>
            <v-btn large text
                   :color="$route.name == 'index' || !$route.name ? 'white' : 'black'"
                   @click="$router.push({name:'menu'})">
                BESTELLEN
            </v-btn>
            <v-btn large text
                   :color="$route.name == 'index' || !$route.name ? 'white' : 'black'"
                   @click="$router.push({name:'contact'})">
                KONTAKT
            </v-btn>
            </div>
        </v-toolbar-title>


        <v-spacer></v-spacer>
        <v-menu v-if="hasCheckedLogin && $vuetify.breakpoint.smAndUp">
            <template v-slot:activator="{ on }">
                <v-avatar
                    v-on="on"
                >
                    <v-icon x-large
                            :color="$route.name == 'index' ? 'white' : 'black'"
                    >{{ mdiAccountCircle }}</v-icon>
                </v-avatar>
            </template>
            <v-list>
                <v-list-item v-if="loggedIn" @click="$router.push({name:'profile'})">Profil</v-list-item>
                <v-list-item v-if="claims.restaurants && claims.restaurants.includes(restaurantId)"
                             @click="$router.push({name:'manage-bookings'})">Inhaberübersicht</v-list-item>
                <v-list-item v-if="loggedIn" @click="signOut">Logout</v-list-item>
                <v-list-item v-if="!loggedIn" @click="$router.push({name:'login'})">Login</v-list-item>
                <v-list-item v-if="!loggedIn" @click="$router.push({name:'register'})">Register</v-list-item>
            </v-list>
        </v-menu>
      <v-layout v-else-if="$vuetify.breakpoint.smAndUp" align-center class="pr-4" justify-end>
        <v-progress-circular
            color="blue"
            indeterminate
            size="22"
            width="3"
        ></v-progress-circular>
      </v-layout>
        <template v-if="$route.name == 'menu'" v-slot:extension>
            <scrollable-categories :categories="foodCategories"></scrollable-categories>
        </template>
        <template v-else-if="$route.name == 'manage-bookings' || $route.name == 'manage-settings'" v-slot:extension>
            <div class="font-weight-bold pa-2 body-1 navItem"
                 @click="$router.push({name:'manage-bookings'})">BUCHUNGEN</div>
            <div class="font-weight-bold pa-2 body-1 navItem" @click="$router.push({name:'manage-settings'})">EINSTELLUNGEN</div>
        </template>

    </v-app-bar>
     <v-navigation-drawer
         v-if="$vuetify.breakpoint.smAndDown"
         v-model="drawer"
         fixed
         app
         temporary
     >
       <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ restaurantString }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{restaurantSubString}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
       <v-divider></v-divider>
        <v-list
            nav
        >
              <v-list-item style="touch-action: manipulation;" :to="{name:'index'}" exact>
                  <v-list-item-icon>
                    <v-icon>{{ mdiHome }}</v-icon>
                  </v-list-item-icon>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item>
              <v-list-item style="touch-action: manipulation;" exact :to="{name:'menu'}">
                  <v-list-item-icon>
                    <v-icon>{{ mdiSilverwareForkKnife }}</v-icon>
                  </v-list-item-icon>
                <v-list-item-title>Bestellen</v-list-item-title>
              </v-list-item>
              <v-list-item style="touch-action: manipulation;" :to="{name:'contact'}" exact>
                  <v-list-item-icon>
                    <v-icon>{{ mdiPhone }}</v-icon>
                  </v-list-item-icon>
                <v-list-item-title>Kontakt</v-list-item-title>
              </v-list-item>
                    <v-divider></v-divider>

              <v-list-item style="touch-action: manipulation;" v-if="loggedIn" :to="{name:'profile'}">
                  <v-list-item-icon>
                    <v-icon>{{ mdiAccountCircle }}</v-icon>
                  </v-list-item-icon>
                <v-list-item-title>Profil</v-list-item-title>
              </v-list-item>
              <v-list-item style="touch-action: manipulation;" v-if="claims.restaurants && claims.restaurants.includes(restaurantId)"
                             :to="{name:'manage-bookings'}">
                  <v-list-item-icon>
                    <v-icon>{{ mdiViewDashboard }}</v-icon>
                  </v-list-item-icon>
                                <v-list-item-title>Inhaberübersicht</v-list-item-title>
              </v-list-item>
              <v-list-item style="touch-action: manipulation;" v-if="loggedIn" @click="signOut">
                  <v-list-item-icon>
                    <v-icon>{{ mdiLogout }}</v-icon>
                  </v-list-item-icon>
                                <v-list-item-title>Logout</v-list-item-title>

              </v-list-item>
              <v-list-item style="touch-action: manipulation;" v-if="!loggedIn" :to="{name:'login'}" exact>
                  <v-list-item-icon>
                    <v-icon>{{ mdiLogin }}</v-icon>
                  </v-list-item-icon>
                                <v-list-item-title>Login</v-list-item-title>

              </v-list-item>
              <v-list-item style="touch-action: manipulation;" v-if="!loggedIn" :to="{name:'register'}" exact>
                  <v-list-item-icon>
                    <v-icon>{{ mdiDoor }}</v-icon>
                  </v-list-item-icon>
                                <v-list-item-title>Registrieren</v-list-item-title>
              </v-list-item>

        </v-list>
       <template v-slot:append >
                <v-img v-if="logoInAppBar" :src="getImgUrl()" contain height="57" class="mb-2"></v-img>
      </template>
      </v-navigation-drawer>
</span>
</template>

<script>
import {mapGetters} from "vuex";
import firebase from 'firebase/app';

const ScrollableCategories = () => import('./ScrollableCategories')
import {mdiAccountCircle, mdiSilverwareForkKnife, mdiPhone, mdiHome, mdiLogin, mdiLogout, mdiViewDashboard, mdiDoor} from '@mdi/js'
import {restaurantName, restaurantString, restaurantSubString, logoInAppBar} from "@/restaurantConfig";

export default {
  name: "AppBar",
  components: {ScrollableCategories},
  data() {
    return {
      isScrolling:false,
      mdiAccountCircle,
      mdiSilverwareForkKnife,
      mdiPhone,
      mdiHome,
      mdiLogin,
      mdiLogout,
      mdiDoor,
      mdiViewDashboard,
      restaurantString,
      restaurantSubString,
      logoInAppBar,
      appBarExtended: true,
      drawer: false,
    }
  },
  computed: {
    ...mapGetters({
      restaurantId: 'restaurantStore/id',
      claims: 'userStore/claims',
      foodCategories: 'menuStore/foodCategories',
      loggedIn: 'userStore/loggedIn',
      hasCheckedLogin: 'userStore/hasCheckedLogin'
    }),
    appBarColor (){
      console.log(this.$route.name)
      if(this.$route.name == 'index' && !this.isScrolling || !this.$route.name){
        return 'transparent'
      } else if(this.$route.name == 'index' && this.isScrolling) {
        return 'rgba(13, 3, 29, .95)'
      } else {
        return 'white'
      }
    }
  },
  methods: {
    onScroll () {
      this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 25
    },
    signOut() {
      firebase
          .auth()
          .signOut()
          .then(() => {
            console.log('logged out')
          });
    },
    getImgUrl() {
      if(logoInAppBar){
        return require('../restaurants/' + restaurantName + '/assets/mini-logo.png')
      } else {
        return
      }
    },
  },
  watch: {
    async appBarExtended(val) {
      if (!val) {
        await new Promise(r => setTimeout(r, 100));
      }
      this.$store.commit('menuStore/SET_APP_BAR_EXTENDED', this.appBarExtended);
    },
    '$route.name': {
      deep: true,
      immediate: true,
      handler: function () {
        this.appBarExtended = true
      }
    }
  }
}
</script>

<style scoped>
.navItem {
  cursor: pointer;
}

.navItem:hover {
  color: var(--v-primary-base);
}

#appBar {
  background-color: var(--v-background-base);
}
</style>

