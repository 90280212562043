import home from '@/restaurants/mryu/home'
const restaurantName = "mryu"
const firebaseTarget = "mryu"
const restaurantId = "u4YS1zVwWU2hbZsTdyf5"
const restaurantString = "Mr.Yu"
const restaurantSubString = "Asia Bistro und Dim Sum"
const showCouponSettings = true
const menuAccentColor = 'darkred'
const logoInAppBar = false

const vuetifyTheme = {
    primary: '#CB9173',
    secondary: '#a491d3',
    accent: '#818aa3',
    error: '#f9dad0',
    background: '#fff',
    cards: '#fff'
}

const extraCategoryPics = []

export {restaurantName, home, firebaseTarget, restaurantId, restaurantString, showCouponSettings, restaurantSubString, menuAccentColor, vuetifyTheme, logoInAppBar, extraCategoryPics}