<template>
    <v-footer
            dark
            padless
            v-intersect="onIntersect"
            app
            absolute
    >
        <v-card
                class="flex"
                flat
                tile
        >

            <v-card-text class="py-2 white--text text-center">
              <div>2020 —
                <span @click="$router.push({name:'impressum'})" class="hoverMe">Impressum</span> -
                <span @click="$router.push({name:'tac'})" class="hoverMe">AGB</span> -
                <span @click="$router.push({name:'privacy'})" class="hoverMe">Datenschutzerklärung</span>

              </div>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
    export default {
        name: "AppFooter",
        methods: {
            onIntersect(entries, observer, isIntersecting) {
                const height = entries[0].target.offsetHeight
                if(isIntersecting) {
                    this.$emit('show', height)
                } else {
                    this.$emit('hide', height)
                }
            }
        }
    }
</script>

<style scoped lang="sass">
.hoverMe
  &:hover
    cursor: pointer
    color: var(--v-primary-base)

</style>
